<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'PointLogList',
              }"
            >點數新增紀錄
            </b-breadcrumb-item
            >
            <b-breadcrumb-item active>點數列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">
          點數新增紀錄
        </h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.POINT_LIST_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-3"
            variant="primary"
            :to="{ name: 'PointCreate' }"
          ><i class="fa fa-plus"></i>新增點數
          </b-button>

          <b-form-select
            class="mr-3"
            v-model="merchantId"
            :options="merchants"
            :disabled="merchants.length == 2"
            @change="fetchPoints"
          ></b-form-select>

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input placeholder="搜尋會員名稱、手機、外部編碼" v-model="search"
              v-on:keyup.enter="fetchPoints()"></b-form-input>
            <b-input-group-append>
              <b-button @click="fetchPoints()"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>

        </div>
      </div>
      <b-table
        striped
        hover
        responsive
        :items="points"
        :fields="fields"
        :busy="isLoading"
        :no-local-sorting="true"
        @sort-changed="handleSort"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            class="mr-1"
            variant="inverse-primary"
            :to="{
              name: 'PointView',
              params: {
                point_id: data.item.id,
                merchant_id: merchantId
              },
            }"
          >查看
          </b-button>
          <b-button
            class="mr-1"
            variant="inverse-warning"
            :to="{
              name: 'PointEdit',
              params: {
                point_id: data.item.id,
                merchant_id: merchantId
              },
            }"
          >編輯
          </b-button>
          <b-button
            variant="inverse-danger"
            @click="deletePoint(data.item)"
          >
            刪除
          </b-button>

        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="organizationList"
          @change="(page) => fetchPoints(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
import pointApi from "../../../apis/point";
import merchantApi from "../../../apis/merchant";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import moment from "moment";

export default {
  data() {
    return {
      consts: consts,
      isLoading: false,
      search: "",
      points: [],
      merchantId: null,
      merchants: [{value: null, text: "請選擇"}],
      sortBy: "created_at",
      orderBy: "desc",
      fields: [
        {
          key: "customer_name",
          label: "會員名稱",
        },
        {
          key: "point",
          label: "點數",
        },
        {
          key: "used_point",
          label: "已用點數",
        },
        {
          key: "created_at",
          label: "入點時間",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return moment(value).format("YYYY-MM-DD HH:mm");
            }
          }
        },
        {
          key: "start_at",
          label: "點數可用日",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return moment(value).format("YYYY-MM-DD HH:mm");
            }
          }
        },
        {
          key: "expired_at",
          label: "點數過期日",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return moment(value).format("YYYY-MM-DD HH:mm");
            }
          }
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchMerchants();
  },
  methods: {
    async fetchPoints(page) {
      try {
        this.isLoading = true;
        const {data} = await pointApi.getPoints(this.merchantId, {
          keyword: this.search,
          page: page || this.currentPage,
          per_page: this.perPage || 15,
          sort_by: this.sortBy,
          order_by: this.orderBy,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.points = data.data;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async fetchMerchants() {
      const {data} = await merchantApi.list();

      this.merchants = [
        {value: null, text: "請選擇"},
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant
      if (data.data.length == 1) {
        defaultMerchant = data.data[0]
      } else {
        defaultMerchant = data.data.filter(merchant => merchant.type == 'LINE').shift()
      }

      if (defaultMerchant) {
        this.merchantId = defaultMerchant.id;
        this.fetchPoints();
      }
    },
    handleSort(ctx) {
      this.sortBy = ctx.sortBy;
      this.orderBy = ctx.sortDesc ? "desc" : "asc";
      this.currentPage = 1;
      this.fetchPoints();
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async deletePoint(point) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await pointApi.removePoint(point.merchant_id, point);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 ",
              });
              this.fetchPoints()
              ();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: '錯誤',
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
  },
};
</script>
